<template>
    <div class="donation">
        <div class="title">{{ $t('donationPage.title') }}</div>
        <p class="description">{{ $t('donationPage.description') }}</p>
        <div class="link-content">
            <p class="link-title">{{ $t('donationPage.link') }}</p>
            <div class="link-container">
                <router-link :to="{ path: '/r', query: { donateTo: this?.$firebase?.account } }" id="link" class="link">{{
                    myURL }}</router-link>
            </div>
            <i class="copy-icon" @click="copyToClipboard"></i>
        </div>

        <div class="QR">
            <div class="generate" @click="generate">
                {{ $t('donationPage.qr') }}
                <i class="generate-icon" :class="{ 'open': generateIsOpen }"></i>
            </div>

            <div class="generated" v-if="generateIsOpen">
                <p class="description">{{ $t('donationPage.qrDescription') }}</p>
                <div class="generated-content">
                    <vue-qr ref="child" id="QR" class="code" :text="myURL"></vue-qr>
                    <div class="buttons">
                        <div class="download btn" @click="openQR">
                            <span>{{ $t('buttons.png') }}</span>
                        </div>
                        <div class="copy" @click="copyQR">{{ $t('buttons.url') }}</div>
                    </div>
                </div>
            </div>
        </div>
        <Pop v-if="isCopied" :popText="popText" />
    </div>
</template>
<script>
import VueQr from 'vue-qr';
import html2canvas from 'html2canvas';
import Pop from '@/components/account/settingPop.vue';
import i18n from '../../i18n';

export default {
    components: {
        VueQr,
        Pop
    },

    data: () => ({
        generateIsOpen: false,
        isCopied: false,
        popText: i18n.t('donationPage.success')

    }),

    computed: {
        myURL() {
            return document?.location?.origin + '/r?donateTo=' + this?.$firebase?.account
        },
    },

    methods: {
        generate() {
            if (this.generateIsOpen == false) {
                this.generateIsOpen = true
            } else {
                this.generateIsOpen = false
            }
        },

        copyToClipboard() {
            const element = document.getElementById("link");
            const text = element.innerText;

            navigator.clipboard.writeText(text)
                .then(() => {
                    this.isCopied = true
                    setTimeout(() => {
                        this.isCopied = false
                    }, 1000)
                })
                .catch((error) => {
                    console.error("Ошибка при копировании текста в буфер обмена:", error);
                });
        },


        openQR() {
            const qrCodeElement = this.$refs.child.$el;
            html2canvas(qrCodeElement, { scale: 5 }).then(canvas => {
                const imageDataURL = canvas.toDataURL('image/png');

                const downloadLink = document.createElement('a');
                downloadLink.href = imageDataURL;
                downloadLink.download = 'qr_code.png';
                downloadLink.click();
            });
        },

        copyQR() {
            navigator.clipboard.writeText(this?.$refs?.child?._data?.imgUrl)
                .then(() => {
                    this.isCopied = true
                    setTimeout(() => {
                        this.isCopied = false
                    }, 1000)
                })
                .catch((error) => {
                    console.error("Ошибка при копировании текста в буфер обмена:", error);
                });
        },

    }
}
</script>
<style lang="scss" scoped>
.donation {
    padding: 40px 25px;
    border-radius: 14px;
    background: #130E25;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    max-width: 548px;
}

.title {
    margin-bottom: 14px;
    color: #FFF;
    font-family: Gilroy-Medium;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
}

.description {
    color: #A2A2A2;
    font-family: Gilroy-Medium;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
}

.link-content {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.link-title {
    color: #A0AEC0;
    font-family: Gilroy-Medium;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.link-container {
    display: flex;
    align-items: center;
    padding: 0px 24px;
    width: 328px;
    height: 44px;
    border-radius: 16px;
    background: rgba(255, 255, 255, 0.10);
}

.link {
    font-family: Gilroy-Medium;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    white-space: nowrap;
    overflow: hidden;

    background: var(--0, linear-gradient(90deg, #D574DE 1.01%, #8E54E9 82.23%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.copy-icon {
    width: 24px;
    height: 24px;
    cursor: pointer;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M19.7894 3.515H18.3381V2.0675C18.3381 0.94625 17.4231 0.03125 16.3019 0.03125H4.95813C3.83688 0.03125 2.92188 0.94625 2.92188 2.0675V18.3837C2.92188 19.505 3.83688 20.42 4.95813 20.42H6.40938V21.8713C6.40938 22.9925 7.32438 23.9075 8.44563 23.9075H19.7894C20.9106 23.9075 21.8256 22.9925 21.8256 21.8713V5.55125C21.8256 4.43 20.9144 3.515 19.7894 3.515ZM3.99812 18.38V2.0675C3.99812 1.535 4.42937 1.10375 4.96187 1.10375H16.3056C16.8381 1.10375 17.2694 1.535 17.2694 2.0675V18.3837C17.2694 18.9163 16.8381 19.3475 16.3056 19.3475H4.95813C4.42938 19.3438 3.99812 18.9125 3.99812 18.38ZM20.7531 21.8675C20.7531 22.4 20.3219 22.8312 19.7894 22.8312H8.44563C7.91313 22.8312 7.48188 22.4 7.48188 21.8675V20.4162H16.3019C17.4231 20.4162 18.3381 19.5012 18.3381 18.38V4.5875H19.7894C20.3219 4.5875 20.7531 5.01875 20.7531 5.55125V21.8675Z' fill='url(%23paint0_linear_2245_19151)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_2245_19151' x1='3.1131' y1='13.7372' x2='18.4656' y2='13.7372' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23D574DE'/%3E%3Cstop offset='1' stop-color='%238E54E9'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
}

.QR {
    margin-top: 40px;
}

.generate {
    display: flex;
    align-items: center;
    width: max-content;
    font-family: Gilroy-Medium;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    cursor: pointer;
    background: var(--0, linear-gradient(90deg, #D574DE 1.01%, #8E54E9 82.23%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.generate-icon {
    width: 11px;
    height: 5px;
    margin-left: 8px;
    cursor: pointer;
    rotate: 180deg;
    transition: .3s;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='6' viewBox='0 0 13 6' fill='none'%3E%3Cpath d='M12 5.5L6.5 0.5L1 5.5' stroke='url(%23paint0_linear_2245_19158)' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_2245_19158' x1='1.11127' y1='2.62979' x2='10.0448' y2='2.62979' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23D574DE'/%3E%3Cstop offset='1' stop-color='%238E54E9'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
}

.open {
    rotate: none;
    transition: .3s;
}

.generated {
    margin-top: 14px;
}

.generated-content {
    margin-top: 24px;
    display: flex;
    align-items: center;
}

.code {
    margin-right: 30px;
    width: 112px;
    height: 112px;
    border-radius: 19px;
}

.download {
    display: flex;
    align-items: center;
    margin-bottom: 14px;
    padding: 0px 24px;
    height: 44px;
    color: #FFF;
    text-align: center;
    font-family: Gilroy-Medium;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    text-transform: uppercase;
}

.copy {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFF;
    text-align: center;
    font-family: Gilroy-Medium;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    text-transform: uppercase;
    height: 44px;
    border-radius: 41px;
    border: 1px solid #FFF;
    background: rgba(13, 11, 23, 0.6);
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.copy:hover {
    transition: .3s;
    background: rgba(255, 255, 255, 0.25);
}

@media (min-width:1800px) {
    .donation {
        max-width: 562px;
    }

    .title {
        font-size: 18px;
    }

    .link-title,
    .generate {
        font-size: 16px;
    }
}

@media (max-width: 680px) {
    .donation {
        padding: 36px 21px;
        max-width: 258px;
    }

    .title {
        font-size: 14px;
    }

    .description {
        font-size: 10px;
    }

    .QR .description {
        font-size: 12px;
    }

    .link-content {
        position: relative;
        flex-direction: column;
        align-items: flex-start;
    }

    .link-title {
        font-size: 12px;
        margin-bottom: 10px;
    }

    .link-container {
        padding: 0px 19px;
        height: 40px;
        width: 182px;
    }

    .link {
        font-size: 12px;
        overflow: hidden;
    }

    .copy-icon {
        position: absolute;
        right: 0;
        bottom: 8px;
    }

    .generated-content {
        flex-direction: column;
    }

    .code {
        margin-right: 0;
        margin-bottom: 40px;
    }

    .download,
    .copy {
        font-size: 12px;
        height: 40px;
    }
}
</style>